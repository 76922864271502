.Social_wrap {
  display: flex;
  display: -ms-flexbox;
}

.Social_wrap > :not(:first-child) {
  margin-left: 18px;
}

@media (max-width: 1024px) {
  .Social_wrap {
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly;
  }
  .Social_wrap > * {
    margin-left: 0;
  }
}

.Social_wrap .Social_facebook,
.Social_wrap .Social_instagram,
.Social_wrap .Social_linkedin,
.Social_wrap .Social_twitter,
.Social_wrap .Social_youtube {
  font-size: 0;
  color: var(--input-grey);
}

.Social_wrap .Social_facebook:hover,
.Social_wrap .Social_instagram:hover,
.Social_wrap .Social_linkedin:hover,
.Social_wrap .Social_twitter:hover,
.Social_wrap .Social_youtube:hover {
  text-decoration: none;
  color: var(--br-grey);
}

.Social_facebook:before,
.Social_instagram:before,
.Social_linkedin:before,
.Social_twitter:before,
.Social_youtube:before {
  font-size: 18px;
  font-weight: 900;
}
